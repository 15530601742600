<template>
    <main class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
        <div class="text-center">
            <div class="i-mdi:success-circle w-128px h-128px text-primary"></div>
            <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Registration Successful</h1>
            <p class="mt-6 text-base leading-7 text-gray-600">Returning to the registration form.</p>
            <div class="mt-10 flex items-center justify-center gap-x-6">
                <NuxtLink
                    to="/"
                    class="rounded-full bg-primary px-6 py-3 text-sm font-semibold text-white shadow-sm hover:bg-secondary disabled:bg-offWhite focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                    >Go back</NuxtLink
                >
            </div>
        </div>
    </main>
</template>

<script setup lang="ts">
// Redirect back to the main page after 3 seconds (in ms)

onMounted(() => {
    setTimeout(async () => {
        await navigateTo("/");
    }, 3 * 1000);
});
</script>
